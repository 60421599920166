<template>
  <div class="remembrance">
    <div class="brance-title">
    <span>{{title}}</span>
    <span @click="jumpToimgdetail(title)">>>&nbsp;更多</span>
    </div>
    <div class="brance-container">
     <div class="brancebanners">
         <div class="block">
                <el-carousel trigger="click" height="480px">
                <el-carousel-item 
                v-for="(item,ind) in remembrancelist2" 
                :key="item.id"
                @click.native="$goto('/detailShow',{id:item.id, isremembrance:title=='工作动态'?true:false})"
               v-if="ind<3"
                >
                    <!-- <h3 class="small">{{ item }}</h3> -->
                    <img style="width:100%; height:100%" :src="item.smallImage" :alt="item.title">
                </el-carousel-item>
                </el-carousel>
            </div>
            <div class="mask1"></div>
     </div>
     <div class="brancelists">
        <ul>
         <li 
         v-for="(item,ind) in remembrancelist" 
         :key="item.id"
         @click="$goto('/detailShow',{id:item.id,isremembrance:title=='工作动态'?true:false})"
         >
		 <!-- v-if="ind>2"-->
         <p>
            <i></i>
            <span class="brancelistscontent">
            {{item.title}}
            </span>
         </p>
         <span>{{item.publishDate | formateData}}</span>
         </li>
        </ul>
     </div> 
    </div>
  </div>
</template>

<script>
export default {
  props: {
      remembrancelist: {
          type: Array,
          default: () => []
      },
      title: {
          type: String,
          default: "工作动态"
      }
  },
  data(){
	  return{
	  }
  },
  name: 'Remembrance',
  computed:{
	  remembrancelist2(){
		  let arr = []
		  this.remembrancelist.forEach(item=>{
		  	if(item.smallImage){
		  		arr.push(item)
		  	}
		  })
		  return arr
	  }
  },
  methods : {
      jumpToimgdetail (title) {
        if (title === '造干志愿者风采') {
        this.$router.push({
        path: '/imgDetail', 
        query: {name:'造干志愿者风采', type:'zgzyzfc'}
        }) 
        } else if (title === '工作动态') {
        this.$router.push({
        path: '/brance2',
        query: {title: '工作动态'}
        }) 
        }
      }
  }

}
</script>

<style lang="less" scoped>
.remembrance {
    .brance-title  {
        cursor: pointer;
        margin-top: 53px;
        font-size: 23px;
        padding-right: 25px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        margin-bottom: 16px;
        span {
            &:nth-child(1) {
            display: flex;
                &::before {
                content: "";
                display: block;
                height: 20px;
                width: 35px;
                background: url('../../../assets/img/title.png') no-repeat center center;
                }
            }
            &:nth-child(2) {
                font-size: 17px;
                color: #ABABAB;
            }
        }
    }
    .brance-container {
        width: 1160px;
		padding: 20px;
        min-height: 480px;
        background-color: #ffffff;
        display: flex;     
        box-shadow: 0px 0px 18px 0px rgba(14, 14, 14, 0.13);
        .brancebanners {
           position: relative;
           width: 606px;
           height: 480px;
           .el-carousel__item h3 {
                    color: #475669;
                    font-size: 14px;
                    opacity: 0.75;
                    line-height: 150px;
                    margin: 0;
                }

                .el-carousel__item:nth-child(2n) {
                    background-color: #99a9bf;
                }
                
                .el-carousel__item:nth-child(2n+1) {
                    background-color: #d3dce6;
                }
                .mask1 {
                    position: absolute;
                    bottom: 0px;
                    left: 0px;
                    width: 646px;
                    height: 40px;
                    z-index: 2;
                    opacity: 0.35;
                }
        }
        .brancelists {
            flex: 1;
            padding: 0 23px;
            li {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
				padding: 10px 0;
				margin-top: 0 !important;
                p {
                    display: flex;
                    color: #333333;
                    width: 356px;
                    i {
                      display: block;
                      width: 16px;
                      height: 16px;
                      background: url('../../../assets/img/list-little.png') no-repeat 0 center;
                    }
                    .brancelistscontent {
                        width: 356px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        color: #333333;
                    }
                }
                span {
                    color: #ABABAB;
					&:hover {
						color: #d1292e;
					}
                }
                &:first-child {
                    margin-top: 33px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
